import hydrateEntry from '../utils/hydrate-entry';
import { v4 as uuidv4 } from 'uuid';
import { isAudit } from '../types/TypeGuards';
import { handleDirtyState } from './handle-dirty-states';
import qs from 'qs';
import { toaster } from '@premisedata/portal-design-system';
import { firebaseConfig } from '../providers/firebaseContext';
import { AppState } from '../types/AppState';
import StackdriverErrorReporter from 'stackdriver-errors-js';

// LIST OF TYPES FOR ALL THE ACTIONS
interface FETCH_ENTRY {
  type: 'FETCH_ENTRY';
  query: qs.ParsedQs;
}

interface ENTRY_FETCHED {
  type: 'ENTRY_FETCHED';
  payload: EntryWithCategory;
  apiKey?: string;
  query: qs.ParsedQs;
}

interface DESELECT_ALL {
  type: 'DESELECT_ALL';
}

interface DELETE_SELECTED {
  type: 'DELETE_SELECTED';
}

interface DELETE_ALL_FOR_AUDIT {
  type: 'DELETE_ALL_FOR_AUDIT';
  id: number;
}

interface TOGGLE_LABEL_VISIBILITY {
  type: 'TOGGLE_LABEL_VISIBILITY';
  label: Label;
}

interface TOGGLE_BB {
  type: 'TOGGLE_BB';
  uuid: string;
  auditUuid: number;
}

interface REPOSITION_BB {
  type: 'REPOSITION_BB';
  uuid: string;
  auditUuid: number;
  x: number;
  y: number;
}

interface RESIZE_BB {
  type: 'RESIZE_BB';
  uuid: string;
  auditUuid: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

interface UPDATE_LABEL_FOR_SELECTED {
  type: 'UPDATE_LABEL_FOR_SELECTED';
  label: Label;
}

interface GROUP_SELECT {
  type: 'GROUP_SELECT';
  audit: number;
  coordinates: {
    x: number;
    y: number;
    h: number;
    w: number;
  };
}

interface SAVE_COMPLETED {
  type: 'SAVE_COMPLETED';
}

interface CREATE_BOUNDING_BOX {
  type: 'CREATE_BOUNDING_BOX';
  audit: number;
  coordinates: {
    x: number;
    y: number;
    h: number;
    w: number;
  };
}
interface TOGGLE_SELECTED_LABEL {
  type: 'TOGGLE_SELECTED_LABEL';
  label: Label;
}
interface SET_LABEL_FILTER {
  type: 'SET_LABEL_FILTER';
  filter: string;
}

interface CHANGE_MODE {
  type: 'CHANGE_MODE';
  mode: 'tag' | 'compare';
}

interface UNDO {
  type: 'UNDO';
}

interface REDO {
  type: 'REDO';
}

interface DISCARD_CHANGES {
  type: 'DISCARD_CHANGES';
}

interface CHANGE_LANGUAGE {
  type: 'CHANGE_LANGUAGE';
  lang: string;
}

// Union type for all the non-bounding box related actions
type GENERIC_ACTIONS =
  | FETCH_ENTRY
  | ENTRY_FETCHED
  | DESELECT_ALL
  | TOGGLE_LABEL_VISIBILITY
  | UPDATE_LABEL_FOR_SELECTED
  | DELETE_SELECTED
  | DELETE_ALL_FOR_AUDIT
  | GROUP_SELECT
  | TOGGLE_SELECTED_LABEL
  | CREATE_BOUNDING_BOX
  | SET_LABEL_FILTER
  | CHANGE_MODE
  | UNDO
  | REDO
  | DISCARD_CHANGES
  | SAVE_COMPLETED
  | CHANGE_LANGUAGE;

// Type for Bounding box specific actions
type BB_ACTIONS = TOGGLE_BB | REPOSITION_BB | RESIZE_BB;

// All actions
export type Actions = GENERIC_ACTIONS | BB_ACTIONS;

// reusable function to select a bounding box from the state based on the action
const selectBbox = (action: BB_ACTIONS, newState: EntryWithCategory) => {
  const audit = newState.content.find((audit: Media) => isAudit(audit) && audit.id === action.auditUuid) as Audit;
  const bbox = audit.bboxes.find((bb) => bb.uuid === action.uuid) as BoundingBox;
  // we need to bring the last selected bbox to the y
  const index = audit.bboxes.indexOf(bbox);
  audit.bboxes.splice(index, 1);
  audit.bboxes.push(bbox);
  return {
    audit,
    bbox
  };
};

// List of destructive actions, which trigger a history update
const isDestructive = (action: Actions): boolean => {
  switch (action.type) {
    case 'DELETE_SELECTED':
    case 'REPOSITION_BB':
    case 'RESIZE_BB':
    case 'UPDATE_LABEL_FOR_SELECTED':
    case 'CREATE_BOUNDING_BOX':
    case 'DISCARD_CHANGES':
    case 'DELETE_ALL_FOR_AUDIT':
      return true;
    default:
      return false;
  }
};

// Check if an action is meant to navigate history
const isHistory = (action: Actions): boolean => {
  switch (action.type) {
    case 'UNDO':
    case 'REDO':
      return true;
    default:
      return false;
  }
};

// Write state to local storage
const saveCache = (key: string, content: Array<Audit | Video | Image>, errorHandler: StackdriverErrorReporter): void => {
  const item = {
    content,
    time: new Date()
  };
  const value = JSON.stringify(item);
  if (value.length > 5_000_000_000) {
    errorHandler.report('Trying to save item to local storage but too big! (Serialised value larger than 5MB) Returning early & skipping cache. Item:\n' + value);
  }
  try {
    localStorage.setItem(key, value);
  } catch (e: any) {
    if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
      localStorage.clear();
      toaster.warning('Local cache cleared to free up space. This entry may be too large to support recovery functionality.', {});
    } else {
      throw e;
    }
  }
};

// Load state from local storage
const readCache = (key: string): { content: Audit[]; time: Date } | null => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    return null;
  }
};

// immutable hack to clone nested objects without modifying the original
const deepClone = (value: object) => JSON.parse(JSON.stringify(value));

const MAX_HISTORY = 20;

// After an action is executed, the state is updated and the history is updated
// History keeps track of the state at different timesteps allowing rewinding
export const handleHistory = (state: AppState, action: Actions) => {
  const newState = reducer(state, action);

  if (!newState.content) {
    state.errorHandler.report(new Error(`Invalid state in handleHistory. Reducer returned a newState without content. newState: ${JSON.stringify(newState)}`));
  }

  if (isDestructive(action)) {
    if (state.history.steps.length >= MAX_HISTORY) {
      state.history.steps.shift();
    }
    const step = deepClone(state.content); // Immutable hack
    const oldHistory = deepClone(state.history.steps);
    newState.history = Object.assign({
      steps: deepClone([...oldHistory, step]),
      current: state.history.current + 1
    });
  }
  if (isDestructive(action) || isHistory(action)) {
    newState.lastInteraction = new Date();
    saveCache(`${newState.id}-${newState.query_category_id}-last-edit`, newState.content, state.errorHandler);
  }

  if (isDestructive(action) || isHistory(action) || action.type === 'ENTRY_FETCHED') {
    newState.dirtyCounts = handleDirtyState(newState);
  }
  return newState;
};

const reducer = (state: AppState, action: Actions): AppState => {
  let newState = Object.assign({}, state);
  const { errorHandler } = state;

  const runUndo = (some_new_state: AppState) => {
    const undoStep = some_new_state.history.current - 1 >= 0 ? some_new_state.history.current - 1 : 0;
    const undoStepContent = some_new_state.history.steps[undoStep];
    if (!undoStepContent) {
      throw new Error(`Invalid data in history for undostep (undefined). some_new_state.history.steps: ${JSON.stringify(some_new_state.history.steps)}`);
    }
    some_new_state.content = undoStepContent;
    some_new_state.history.current = undoStep;
    return some_new_state;
  };
  switch (action.type) {
    case 'FETCH_ENTRY':
      return {
        ...newState,
        loading: true,
        query: action.query
      };
    case 'ENTRY_FETCHED':
      const hydrated = hydrateEntry(action.payload);
      const lastFetched = [...hydrated.content];
      newState.query = action.query;

      saveCache(`${action.payload.id}-${action.payload.query_category_id}-last-fetch`, hydrated.content, errorHandler);

      let cachedEdits: {
        content: Audit[];
        time: Date;
      } | null = null;

      try {
        cachedEdits = readCache(`${action.payload.id}-${action.payload.query_category_id}-last-edit`);
        if (cachedEdits) {
          if (cachedEdits.content) {
            console.log('found pending edits, restoring.', cachedEdits);

            const label_id_to_color_map = {} as Record<number, string>;

            action.payload.labels.forEach((label) => {
              label_id_to_color_map[label.id] = label.color;
            });
            cachedEdits.content.forEach((audit) => {
              console.log(`Checking for ${audit.id}`);
              audit.bboxes.forEach((bbox) => {
                if (bbox.fullLabel) {
                  if (bbox.fullLabel.id !== bbox.label_id) {
                    throw `Labels dont match for ${bbox.label_id} and ${bbox.fullLabel.id}.`;
                  }
                  bbox.fullLabel.color = label_id_to_color_map[bbox.fullLabel.id];
                }
              });
            });

            hydrated.content = cachedEdits.content;
          } else {
            errorHandler.report(`Invalid data in cache. Found pending edits, but "content" was undefined. Cached Edits: ${JSON.stringify(cachedEdits)}`);
          }
        }
      } catch {
        errorHandler.report({
          name: 'Error restoring DS cache',
          message: JSON.stringify({
            fetchedData: lastFetched,
            cachedData: cachedEdits || { errorReadingCache: true }
          })
        });
      }

      return {
        ...newState,
        ...hydrated,
        startTime: Date.now(),
        loading: false,
        lastFetched,
        labeling: action.payload.labeling,
        box_manipulation: action.payload.box_manipulation ? action.payload.box_manipulation : false,
        notice: action.payload.notice,
        history: {
          steps: JSON.parse(JSON.stringify([hydrated.content])),
          current: 0
        },
        selectedLabel: !action.payload.labeling ? hydrated.labels[0] : newState.selectedLabel
      };
    case 'TOGGLE_BB': {
      const { bbox } = selectBbox(action, newState);
      const selectedMatch = state.selectedBboxes.includes(bbox.uuid);
      if (!selectedMatch) {
        newState.selectedBboxes.push(action.uuid);
      } else {
        newState.selectedBboxes = state.selectedBboxes.filter((id) => id !== action.uuid);
      }
      return {
        ...state,
        ...newState
      };
    }
    case 'REPOSITION_BB': {
      const { bbox } = selectBbox(action, newState);
      bbox.x = action.x;
      bbox.y = action.y;
      if (!state.box_manipulation) {
        newState = runUndo(newState);
      }
      return {
        ...state,
        ...newState
      };
    }
    case 'RESIZE_BB': {
      const audit = newState.content.find((audit: Media) => isAudit(audit) && audit.id === action.auditUuid) as Audit;
      const bbox = audit.bboxes.find((bb) => bb.uuid === action.uuid) as BoundingBox;

      bbox.x = action.x;
      bbox.y = action.y;
      bbox.width = action.width;
      bbox.height = action.height;
      if (!state.box_manipulation) {
        newState = runUndo(newState);
      }
      return {
        ...state,
        ...newState
      };
    }

    case 'DESELECT_ALL': {
      newState.selectedBboxes = [];
      return newState;
    }

    case 'UPDATE_LABEL_FOR_SELECTED': {
      newState.content.map((audit: Media) => {
        if (isAudit(audit)) {
          audit.bboxes &&
            audit.bboxes.map((bb) => {
              if (newState.selectedBboxes.includes(bb.uuid)) {
                bb.label_id = action.label.id;
                bb.fullLabel = action.label;
              }
            });
        }
      });
      newState.selectedBboxes = [];
      return newState;
    }

    case 'TOGGLE_SELECTED_LABEL': {
      if (action.label !== newState.selectedLabel) {
        newState.selectedLabel = action.label;
        newState.visibleLabels.length > 0 && !newState.visibleLabels.includes(action.label.id) && newState.visibleLabels.push(action.label.id);
      } else {
        newState.selectedLabel = undefined;
      }
      return {
        ...newState
      };
    }

    case 'TOGGLE_LABEL_VISIBILITY': {
      const match = newState.visibleLabels.find((label) => label === action.label.id);
      if (match) {
        // Match, the label is currently visible, we hide it
        newState.visibleLabels = newState.visibleLabels.filter((label) => label !== action.label.id);
        newState.selectedLabel = newState.selectedLabel?.name === action.label.name ? undefined : newState.selectedLabel;
      } else {
        newState.visibleLabels.push(action.label.id);
        if (!newState.visibleLabels.includes(action.label.id)) {
          newState.selectedLabel = undefined;
        }
      }
      return newState;
    }

    case 'DELETE_SELECTED': {
      if (document.activeElement?.id == 'sidebar-label-filter') {
        return newState;
      }
      if (state.box_manipulation) {
        newState.content = newState.content.map((item) => {
          if (isAudit(item)) {
            item.bboxes = item.bboxes.filter((bb) => !newState.selectedBboxes.includes(bb.uuid));
          }
          return item;
        });
        newState.selectedBboxes = [];
      } else {
        toaster.warning("You can't delete boxes here.", {});
      }

      return newState;
    }

    case 'DELETE_ALL_FOR_AUDIT': {
      if (state.box_manipulation) {
        newState.content = newState.content.map((item) => {
          if (isAudit(item) && item.id === action.id) {
            item.bboxes = [];
          }
          return item;
        });
        newState.selectedBboxes = [];
      } else {
        toaster.warning("You can't delete boxes here.", {});
      }
      return newState;
    }

    case 'GROUP_SELECT': {
      const audit = newState.content.find((audit: Media) => isAudit(audit) && audit.id === action.audit) as Audit;
      audit.bboxes.map((bb) => {
        if (
          bb.x < action.coordinates.x + action.coordinates.w &&
          bb.x + bb.width > action.coordinates.x &&
          bb.y < action.coordinates.y + action.coordinates.h &&
          bb.y + bb.height > action.coordinates.y
        ) {
          newState.selectedBboxes.push(bb.uuid);
        }
      });
      return {
        ...newState
      };
    }

    case 'CREATE_BOUNDING_BOX': {
      if (!state.box_manipulation) {
        toaster.warning('You cant create boxes.', {});
        return { ...newState };
      }
      const audit = newState.content.filter(isAudit).find((item: Audit) => item.id === action.audit);

      const bboxesCount = audit?.bboxes.length || 0;

      if (newState.max_boxes_per_audit && bboxesCount >= newState.max_boxes_per_audit) {
        toaster.negative(`Reached the limit of ${newState.max_boxes_per_audit} bounding boxes per image`, {});
        return {
          ...newState
        };
      }

      if (!audit) {
        throw 'Missing!';
      }

      if (newState.selectedLabel) {
        audit.bboxes.push({
          width: action.coordinates.w,
          height: action.coordinates.h,
          x: action.coordinates.x,
          y: action.coordinates.y,
          label_id: newState.selectedLabel.id,
          fullLabel: newState.selectedLabel,
          auditUuid: audit.id,
          uuid: uuidv4()
        });
      }
      return {
        ...newState
      };
    }

    case 'SET_LABEL_FILTER': {
      newState.labelFilter = action.filter;
      if (action.filter) {
        newState.visibleLabels = newState.labels.filter((label) => label.name.toLowerCase().includes(action.filter)).map((l) => l.id);
      } else {
        newState.visibleLabels = [];
      }
      return {
        ...newState
      };
    }

    case 'CHANGE_MODE': {
      return {
        ...newState,
        mode: action.mode
      };
    }

    case 'CHANGE_LANGUAGE': {
      return {
        ...newState,
        defaultLanguage: action.lang
      };
    }

    case 'UNDO': {
      const undoStep = newState.history.current - 1 >= 0 ? newState.history.current - 1 : 0;
      newState.content = newState.history.steps[undoStep];
      newState.history.current = undoStep;
      return { ...newState };
    }

    case 'REDO': {
      const redoStep = newState.history.current + 1 <= newState.history.steps.length - 1 ? newState.history.current + 1 : newState.history.steps.length - 1;
      newState.content = newState.history.steps[redoStep];
      newState.history.current = redoStep;
      return { ...newState };
    }

    case 'DISCARD_CHANGES': {
      const lastFetched = readCache(`${newState.id}-${newState.query_category_id}-last-fetch`);
      if (lastFetched) {
        newState.content = lastFetched.content;
      }
      return newState;
    }
    case 'SAVE_COMPLETED': {
      newState.saveCompleted = true;
      localStorage.removeItem(`${newState.id}-${newState.query_category_id}-last-fetch`);
      localStorage.removeItem(`${newState.id}-${newState.query_category_id}-last-edit`);
      return {
        ...newState,
        lastFetched: newState.content
      };
    }
    default:
      return state;
  }
};

export default handleHistory;
