import { Styletron, Server } from '@premisedata/portal-design-system';

const getHydrateClass = () => document.getElementsByClassName('_styletron_hydrate_') as HTMLCollectionOf<HTMLStyleElement>;

export const styletron =
  typeof window === 'undefined'
    ? new Server()
    : new Styletron({
        hydrate: getHydrateClass()
      });
