import { v4 as uuidv4 } from 'uuid';
import * as d3 from 'd3-color';
import { isAudit } from '../types/TypeGuards';

export const generateUniqueUUID = (bboxCache: string[]) => {
  let tempUUID = uuidv4();
  while (bboxCache.includes(tempUUID)) {
    tempUUID = uuidv4();
  }
  bboxCache.push(tempUUID);
  return tempUUID;
};

const hydrateEntry = (entry: EntryWithCategory) => {
  console.log('started hydrating', entry);

  const bboxCache = entry.content
    .filter(isAudit)
    .map((audit) => audit.bboxes.map((bbox) => bbox.uuid))
    .flat();

  entry.content.filter(isAudit).map((item) => {
    const audit = item;
    audit.bboxes.map((bb) => {
      bb.uuid = bb.uuid ? bb.uuid : generateUniqueUUID(bboxCache);
      bb.auditUuid = audit.id;
      const foundLabel = entry.labels.find((label) => bb.label_id === label.id);
      if (!foundLabel) {
        throw `Missing label for ${JSON.stringify(bb)}`;
      }
      bb.fullLabel = foundLabel;
      return bb;
    });
  });
  console.log('Finished hydrating', entry);
  return entry;
};

export default hydrateEntry;
