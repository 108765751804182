import * as React from 'react';
import { getAuth } from 'firebase/auth';
import type { Auth, User } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

type ContextState = {
  auth?: Auth;
  user?: User;
  setUser: (user: User) => void;
};

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};

const FirebaseAuthContext = React.createContext<ContextState | undefined>(undefined);

const FirebaseAuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = React.useState<Auth>();
  const [user, setUser] = React.useState<User>();
  const value = { auth, user, setUser };

  React.useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    setAuth(auth);

    return getAuth().onAuthStateChanged((authUser) => {
      setUser(authUser || undefined);
    });
  }, []);

  return <FirebaseAuthContext.Provider value={value}>{children}</FirebaseAuthContext.Provider>;
};

function useFirebaseAuth() {
  const context = React.useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error('useFirebaseAuth must be used within a FirebaseAuthProvider');
  }
  return context;
}

export { FirebaseAuthProvider, useFirebaseAuth };
