import './main.css';
import { AppProps } from 'next/app';
import React from 'react';
import { StoreProvider } from '../stores/store';
import Head from 'next/head';
import { styletron } from '../styletron';
import { BaseProvider, StyletronProvider, LightTheme, ToasterContainer } from '@premisedata/portal-design-system';
import { FirebaseAuthProvider } from '../providers/firebaseContext';

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <StyletronProvider value={styletron}>
      <BaseProvider theme={LightTheme}>
        <ToasterContainer autoHideDuration={10000}>
          <FirebaseAuthProvider>
            <StoreProvider>
              <Head>
                <title>Label Maker</title>
                <link rel="icon" href="favicon.svg" />
              </Head>
              <link rel="preconnect" href="https://fonts.gstatic.com" />
              <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap" rel="stylesheet" />
              <Component {...pageProps} />
            </StoreProvider>
          </FirebaseAuthProvider>
        </ToasterContainer>
      </BaseProvider>
    </StyletronProvider>
  );
}
