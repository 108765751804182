import { isAudit } from '../types/TypeGuards';
import { AppState } from '../types/AppState';

// show diffs from the last commited state
export const handleDirtyState = ({ content, lastFetched }: AppState) => {
  if (!lastFetched) {
    return {
      added: 0,
      modified: 0,
      removed: 0
    };
  }

  const flatContent = content
    .filter(isAudit)
    .map((audit) => audit.bboxes)
    .flat();
  const flatLastFetched = lastFetched
    .filter(isAudit)
    .map((audit) => audit.bboxes)
    .flat();
  const fetchedIds = flatLastFetched.map((i) => i.uuid);
  const currentIds = flatContent.map((i) => i.uuid);

  const added = currentIds.filter((id) => !fetchedIds.includes(id)).length;
  const removed = fetchedIds.filter((id) => !currentIds.includes(id)).length;

  const modified = flatContent.filter((item) => {
    const match = flatLastFetched.find((i) => i.uuid === item.uuid);
    return match && (match.label_id !== item.label_id || match.height !== item.height || match.width !== item.width || match.x !== item.x || match.y !== item.y);
  }).length;

  return {
    added,
    modified,
    removed
  };
};

export const cleanupOldCache = async () => {
  const loadItem = (key: string) => JSON.parse(localStorage.getItem(key) as string);

  const items = Object.keys(localStorage);
  items.map((key) => {
    if (key.includes('-last-')) {
      const data = loadItem(key);
      const dateTime = new Date(data.time);
      const cutoff = new Date(Date.now() - 86400 * 3 * 1000);

      if (dateTime < cutoff) {
        localStorage.removeItem(key);
      }
    }
  });
};
